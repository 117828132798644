<script setup lang="ts">
/********************
 * PROPS & EMITS     *
 ********************/
export interface CiBreadcrumbProps {
  background?: 'white' | 'gray';
  items: any[];
  scrollableClasses?: string;
  showHome?: boolean;
}
const props = withDefaults(defineProps<CiBreadcrumbProps>(), {
  background: 'white',
  scrollableClasses: '',
  showHome: true,
});

/********************
 * REFS & VARS       *
 ********************/
const scrollableContainerRef = ref<HTMLElement | null>(null);
const scrollableRef = ref<HTMLElement | null>(null);

/********************
 * COMPOSITIONS      *
 ********************/
const localePath = useLocalePath();
const route = useRoute();
const router = useRouter();
const runtimeConfig = useRuntimeConfig();
const { $gettext } = useGettext();
const { showRightGradient, showLeftGradient } = useScrollable({
  scrollableContainerRef,
  scrollableRef,
});

const jsonLd = computed(() => {
  if (!props.items?.length) {
    return {};
  }

  const items = props.items.map((ele, idx) => {
    let link;

    if (ele.to) {
      const resolved = router.resolve(ele.to);
      link = `${runtimeConfig.public.baseUrl}${resolved.href}`;
    } else {
      link = `${runtimeConfig.public.baseUrl}${route.path}`;
    }

    return {
      '@type': 'ListItem',
      'position': idx + 1,
      'item': {
        '@id': link,
        'name': escapeHtmlAttribute(ele.name),
      },
    };
  });

  return {
    '@context': 'http://schema.org/',
    '@type': 'BreadcrumbList',
    '@id': '#breadcrumb',
    'itemListElement': items,
  };
});

/********************
 * INITIALIZATION    *
 ********************/
if (props.items.length) {
  useHead({
    script: [
      {
        type: 'application/ld+json',
        children: JSON.stringify(jsonLd.value),
      },
    ],
  });
}

/********************
 * FUNCTIONS         *
 ********************/
function getItemClass(item: any) {
  if (item.class === 'breadcrumb-item--chevron') {
    return 'before:content-["›"] before:text-primary before:text-2xl before:leading-4 before:mt-[-5px] before:overflow-hidden before:pr-2 no-cls-class';
  }
  return item.class || '';
}
</script>

<template>
  <nav
    ref="scrollableContainerRef"
    class="relative overflow-x-auto"
    aria-label="breadcrumb"
  >
    <ol
      ref="scrollableRef"
      class="scrollable m-0 flex whitespace-nowrap bg-transparent bg-none p-0"
      :class="props.scrollableClasses"
    >
      <li
        v-if="props.showHome"
        class="flex flex-row items-center pr-2"
        aria-current="page"
      >
        <nuxt-link
          :to="localePath({ name: 'index' })"
          class="text-black hover:text-primary hover:no-underline"
        ><span>{{ $gettext('Home') }}</span></nuxt-link>
      </li>
      <template v-for="(item, index) in props.items">
        <li
          v-if="!item.hidden"
          :key="`${index}-${encodeURIComponent(item.name)}`"
          :class="getItemClass(item)"
          class="flex flex-row items-center pl-0 pr-2"
          aria-current="page"
        >
          <nuxt-link
            v-if="item.to"
            :to="item.to"
            class="text-black hover:text-primary hover:no-underline"
          >
            <span>{{ item.name }}</span>
          </nuxt-link>
          <span v-if="!item.to">{{ item.name }}</span>
        </li>
      </template>
    </ol>
    <div
      v-if="showLeftGradient"
      class="absolute bottom-0 left-0 h-6 w-5"
      :style="[
        props.background === 'gray'
          ? 'background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, #f5f5f5 100%);'
          : 'background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, #fff 100%)',
      ]"
    />
    <div
      v-if="showRightGradient"
      class="absolute bottom-0 right-0 h-6 w-5"
      :style="[
        props.background === 'gray'
          ? 'background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #f5f5f5 100%);'
          : 'background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fff 100%);',
      ]"
    />
  </nav>
</template>

<style lang="scss" scoped>
// .breadcrumb {
//   &::-webkit-scrollbar {
//     display: none;
//   }
// }
</style>
